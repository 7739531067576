import { FC, Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { NominatedMember } from 'api/dto'
import { Button } from 'ui/src/components/forms'

export interface SendReminderDialogProps {
  readonly open: boolean
  readonly nominatedMember: NominatedMember
  onSubmit: () => void
  onClose: () => void
}

export const SendReminderDialogComponent: FC<SendReminderDialogProps> = ({
  open,
  nominatedMember,
  onSubmit,
  onClose,
}) => {
  const [step, setStep] = useState('NOMINATION_PENDING')

  const sendReminderStep = async () => {
    try {
      await onSubmit()
      setStep('REMINDER_SENT')
    } catch (error) {
      setStep('EMAIL_SENDING_FAILED')
    }
  }

  const NominationPendingStep = () => {
    setStep('NOMINATION_PENDING')
  }

  const inputRef = useRef(null)

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={inputRef}
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
        onClose={onClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform overflow-hidden rounded-lg bg-white p-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:align-middle md:min-w-[400px]">
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  onClick={() => {
                    onClose()
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {step === 'NOMINATION_PENDING' && (
                <StepNominationPending
                  nominatedMember={nominatedMember}
                  sendReminderStep={sendReminderStep}
                />
              )}
              {step === 'REMINDER_SENT' && (
                <StepReminderSent nominatedMember={nominatedMember} onClose={onClose} />
              )}
              {step === 'EMAIL_SENDING_FAILED' && (
                <StepEmailSendingFailed nominationPendingStep={NominationPendingStep} />
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

function StepNominationPending({
  nominatedMember,
  sendReminderStep,
}: {
  nominatedMember: NominatedMember
  sendReminderStep: () => void
}) {
  return (
    <>
      <div className="mb-6 flex justify-center">
        <div className="w-fit rounded-full bg-[#FFF2E3] p-5 text-[#DEAF78]">
          <UserCircleIcon className="h-14 w-14" />
        </div>
      </div>
      <div className="mb-6 mt-4 justify-center ">
        <div className="mb-2 text-center text-2xl font-bold">Nomination Pending</div>
        <div className="text-center">
          {`Looks like ${
            nominatedMember.firstName ? nominatedMember.firstName : nominatedMember.email
          } has not yet completed the application process. 
        We would be happy to send them a reminder to do so on your behalf, 
        so that they can get access to everything mp has to offer sooner.`}
        </div>
      </div>
      <div className="text-center">
        <Button className="text-xs leading-4" uiType="primary" onClick={sendReminderStep}>
          Send Reminder
        </Button>
      </div>
    </>
  )
}

function StepReminderSent({
  nominatedMember,
  onClose,
}: {
  nominatedMember: NominatedMember
  onClose: () => void
}) {
  return (
    <>
      <div className="mb-6 flex justify-center">
        <div className="w-fit rounded-full bg-success-card-green-300 p-5 text-success-card-green-800">
          <CheckCircleIcon className="h-14 w-14" />
        </div>
      </div>
      <div className="mb-6 mt-4 justify-center ">
        <div className="mb-2 text-center text-2xl font-bold">Reminder Sent</div>
        <div className="text-center">
          {`Thank you! ${
            nominatedMember.firstName ? nominatedMember.firstName : nominatedMember.email
          }  will be notified to complete the mp application process, 
          and we are looking forward to hopefully welcoming them as a member soon!`}
        </div>
      </div>
      <div className="text-center">
        <Button className="text-xs leading-4" uiType="primary" onClick={onClose}>
          OK
        </Button>
      </div>
    </>
  )
}

function StepEmailSendingFailed({ nominationPendingStep }: { nominationPendingStep: () => void }) {
  return (
    <>
      <div className="mb-6 flex justify-center">
        <div className="w-fit rounded-full bg-[#FFF2E3] p-5 text-[#DEAF78]">
          <UserCircleIcon className="h-14 w-14" />
        </div>
      </div>
      <div className="mb-6 mt-4 justify-center ">
        <div className="mb-2 text-center text-2xl font-bold">Email not sent</div>
        <div className="text-center">
          Looks like we are having trouble sending the email at the moment. Please try again in 5
          minutes or reach out to concirege@meetperry.com
        </div>
      </div>
      <div className="text-center">
        <Button className="text-xs leading-4" uiType="primary" onClick={nominationPendingStep}>
          Go Back
        </Button>
      </div>
    </>
  )
}

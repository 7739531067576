import { FC, useState } from 'react'
import { BellIcon } from '@heroicons/react/24/outline'
import { NominatedMember, ProspectStatus } from 'api/dto'
import { axios } from 'api/lib'
import { AmityAvatar } from 'ui/components/content'
import { Link } from 'ui/components/navigation'
import { useRoutingContext } from 'ui/lib/navigation'
import { SendReminderDialogComponent } from './SendReminderDialog'

type NominatedMemberProps = {
  nominatedMember: NominatedMember
}
export type NominationStatus = 'Pending' | 'Joined' | 'Declined by mp' | 'Declined by nominee'

export const NominatedMemberElement: FC<NominatedMemberProps> = ({ nominatedMember }) => {
  const [sendReminderDialogOpen, setSendReminderDialogOpen] = useState(false)

  const { baseUrl } = useRoutingContext()

  const initials = getInitials(nominatedMember)

  const showSendReminderDialog = () => {
    setSendReminderDialogOpen(true)
  }
  const closeSendReminderDialog = () => {
    setSendReminderDialogOpen(false)
  }
  const submitReminderEmail = async () => {
    await axios.post(`/api/nominations/${nominatedMember.id}/reminder-email`)
  }

  return (
    <>
      <SendReminderDialogComponent
        open={sendReminderDialogOpen}
        nominatedMember={nominatedMember}
        onSubmit={submitReminderEmail}
        onClose={closeSendReminderDialog}
      />
      <div className="flex gap-x-4 py-2">
        <div>
          {nominatedMember.prospect.socialProfile?.avatarFileId ? (
            <AmityAvatar
              className="h-11 w-11"
              fileId={nominatedMember.prospect.socialProfile?.avatarFileId}
            />
          ) : (
            <span className="bg-deep-teal-500 flex h-11 w-11 items-center justify-center rounded-full">
              <span className="font-brand text-taupe-500 text-base font-medium lining-nums leading-none">
                {initials}
              </span>
            </span>
          )}
        </div>
        <div className="w-full">
          {nominatedMember.prospect.userId && nominatedMember.prospect.status === 'JOINED' ? (
            <Link
              href={`${baseUrl}/members/${nominatedMember.prospect.userId}`}
              className="text-deep-teal-500 text-base font-semibold leading-5"
            >
              {nominatedMember.firstName && nominatedMember.lastName
                ? `${nominatedMember.firstName} ${nominatedMember.lastName}`
                : nominatedMember.email}
            </Link>
          ) : (
            <div className="text-deep-teal-500 text-base font-semibold leading-5">
              {nominatedMember.firstName && nominatedMember.lastName
                ? `${nominatedMember.firstName} ${nominatedMember.lastName}`
                : nominatedMember.email}
            </div>
          )}

          <div className="text-deep-teal-300 text-sm leading-5">{`Status: ${getNominationStatus(
            nominatedMember.prospect.status,
          )}`}</div>
          {nominatedMember.prospect.status === 'CREATED' && (
            <div
              className="flex w-full cursor-pointer items-center gap-x-1 text-sm font-medium leading-4 text-orange-600"
              onClick={showSendReminderDialog}
            >
              <BellIcon className="h-3 w-3" />
              <span>Send Reminder</span>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

const getInitials = (user: { firstName?: string; lastName?: string; email: string }): string => {
  if (user.firstName && user.lastName) {
    return [user?.firstName, user?.lastName]
      .filter(Boolean)
      .map((name) => name.substring(0, 1)?.toUpperCase())
      .join('')
  } else {
    return user.email.substring(0, 1).toUpperCase()
  }
}

function getNominationStatus(prospectStatus: ProspectStatus): NominationStatus {
  if (prospectStatus === 'DECLINED_BY_ADMIN') {
    return 'Declined by mp'
  }

  if (prospectStatus === 'DECLINED_BY_PROSPECT') {
    return 'Declined by nominee'
  }

  if (prospectStatus === 'JOINED') {
    return 'Joined'
  }

  return 'Pending'
}

import { FC } from 'react'

export const NominatedMembersListLoadingState: FC = () => {
  return (
    <div data-testid="loading">
      <div className="mb-2 flex items-center gap-2">
        <div className="h-4 w-full bg-slate-200" />
      </div>
      <div className="mb-4 flex items-center gap-2">
        <div className="h-4 w-4/6 bg-slate-200" />
      </div>
      <div className="mb-4 max-h-[400px] overflow-auto">
        <NominatedMemberElementLoadingState />
        <NominatedMemberElementLoadingState />
        <NominatedMemberElementLoadingState />
        <NominatedMemberElementLoadingState />
        <NominatedMemberElementLoadingState />
        <div className="bg-deep-teal-100 my-2 h-px last-of-type:hidden" />
      </div>
      <div className="h-[24px] w-full shrink-0 self-start rounded-md bg-slate-200" />
    </div>
  )
}

const NominatedMemberElementLoadingState: FC = () => {
  return (
    <div className="flex gap-x-4 py-2">
      <div>
        <div className="h-9 w-9 shrink-0 self-start rounded-full bg-slate-200" />
      </div>
      <div className="w-full">
        <div className="h-4 w-full bg-slate-200" />
        <div className="h-4 w-full bg-slate-200" />
      </div>
    </div>
  )
}

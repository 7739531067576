import { FC, Fragment } from 'react'
import { NominatedMember } from 'api/dto'
import { useRoutingContext } from 'ui/lib/navigation'
import { Button } from 'ui/src/components/forms'
import { NominatedMemberElement } from './NominatedMemberElement'
import { NominatedMembersListLoadingState } from './NominatedMembersListLoadingState'

type NominatedMembersListProps = {
  nominatedMembers: NominatedMember[]
  isLoading: boolean
}

export const NominatedMembersList: FC<NominatedMembersListProps> = ({
  nominatedMembers = [],
  isLoading,
}) => {
  const { baseUrl } = useRoutingContext()
  if (isLoading) {
    return <NominatedMembersListLoadingState />
  }

  return (
    <div className="flex h-full flex-1 flex-col gap-4">
      <div className="flex items-center gap-2">
        <div className="text-deep-teal-900 text-base font-bold leading-5">My nominated members</div>
        {nominatedMembers.length > 0 && (
          <div className="text-deep-gray-600 bg-deep-gray-100 h-5 rounded-full px-2.5 py-0.5 text-xs font-semibold leading-4">
            {nominatedMembers.length}
          </div>
        )}
      </div>
      {nominatedMembers.length > 0 && (
        <div className="flex items-center gap-2">
          <div className="text-deep-teal-900 text-sm font-medium leading-5">Members Joined</div>
          <div className="text-deep-gray-600 bg-deep-gray-100 h-5 items-center rounded-full px-2.5 py-0.5 text-xs font-semibold leading-4">
            {nominatedMembers.filter((n) => n.prospect.status === 'JOINED').length}
          </div>
        </div>
      )}
      <div className="flex flex-1 flex-col justify-between gap-4 overflow-hidden">
        {nominatedMembers.length > 0 ? (
          <div className="h-full overflow-auto sm:max-h-[400px]">
            {nominatedMembers.map((nomination) => (
              <Fragment key={nomination.id}>
                <NominatedMemberElement nominatedMember={nomination} />
                <div className="bg-deep-teal-100 my-2 h-px last-of-type:hidden" />
              </Fragment>
            ))}
          </div>
        ) : (
          <div className="text-deep-teal-500 text-sm font-normal">
            Each new member is a force multiplier of the community. Know someone of like mind?
          </div>
        )}
        <Button
          className="w-full text-xs leading-4"
          uiType="primary"
          href={`${baseUrl}/nominations`}
          data-trackclick={JSON.stringify({ action: 'Clicked Nominate Member - Homepage' })}
        >
          Nominate a member
        </Button>
      </div>
    </div>
  )
}
